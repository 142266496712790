import {
  Expense,
  IncompleteExpense,
  InstallmentExpense,
  RecurringExpense,
} from '../models/Expense'
import { del, get, patch, post, put } from '../utils/api'

import { Category } from '../models/Category'

type ExpenseData = Pick<
  Expense,
  'name' | 'date' | 'currency' | 'total' | 'type' | 'notes'
> & {
  category_id: Category['id']
} // & { payment_method_id?: PaymentMethod['id'] }

type RecurringExpenseData = Pick<
  RecurringExpense,
  'name' | 'total' | 'frequency' | 'currency' | 'type'
> & {
  category_id: Category['id']
} // & { payment_method_id?: PaymentMethod['id'] }

type IncompleteExpenseData = Pick<
  IncompleteExpense,
  'name' | 'date' | 'currency' | 'type'
> & {
  category_id: Category['id']
} // & { payment_method_id?: PaymentMethod['id'] }

type InstallmentExpenseData = ExpenseData & { installments: number }

const expensesAPI = {
  fetchExpensesForRange: (from: Expense['date'], to: Expense['date']) =>
    get<Expense[]>('/expenses', { from, to }),

  fetchLastNExpenses: (n: number) => get<Expense[]>('/expenses/lastn', { n }),

  fetchExpensesByTag: (tag: Expense['tags'][number]) =>
    get<Expense[]>(`/expenses/tag/${tag}`),

  createExpense: (expenseData: ExpenseData) =>
    post<Expense>('/expenses', expenseData),

  editExpense: ({
    id,
    ...expenseData
  }: Partial<ExpenseData> & { id: Expense['id'] }) =>
    patch<Expense>(`/expenses/${id}`, expenseData),

  deleteExpense: (id: Expense['id']) => del<void>(`/expenses/${id}`),

  fetchRecurringExpenses: () => get<RecurringExpense[]>('/recurring-expenses'),

  createRecurringExpense: (recurringExpenseData: RecurringExpenseData) =>
    post<RecurringExpense>('/recurring-expenses', recurringExpenseData),

  editRecurringExpense: ({
    id,
    ...recurringExpenseData
  }: Partial<RecurringExpenseData> & {
    id: RecurringExpense['id']
  }) => patch<Expense>(`/recurring-expenses/${id}`, recurringExpenseData),

  deleteRecurringExpense: (id: RecurringExpense['id']) =>
    del<void>(`/recurring-expenses/${id}`),

  fetchIncompleteExpenses: () =>
    get<IncompleteExpense[]>('/incomplete-expenses'),

  editIncompleteExpense: ({
    id,
    ...incompleteExpenseData
  }: Partial<IncompleteExpenseData> & {
    id: IncompleteExpense['id']
  }) =>
    patch<IncompleteExpense>(
      `/incomplete-expenses/${id}`,
      incompleteExpenseData
    ),

  completeExpense: ({
    id,
    total,
  }: {
    id: IncompleteExpense['id']
    total: Expense['total']
  }) => put<IncompleteExpense>(`/incomplete-expenses/${id}`, { total }),

  deleteIncompleteExpense: (id: IncompleteExpense['id']) =>
    del<void>(`/incomplete-expenses/${id}`),

  fetchInstallmentExpenses: () =>
    get<InstallmentExpense[]>('/installment-expenses'),

  createInstallmentExpense: (installmentExpenseData: InstallmentExpenseData) =>
    post<InstallmentExpense>('/installment-expenses', installmentExpenseData),

  editInstallmentExpense: ({
    id,
    ...installmentExpenseData
  }: Partial<InstallmentExpenseData> & {
    id: InstallmentExpense['id']
  }) => patch<Expense>(`/installment-expenses/${id}`, installmentExpenseData),

  deleteInstallmentExpense: (id: InstallmentExpense['id']) =>
    del<void>(`/installment-expenses/${id}`),
}

export default expensesAPI
