import tagsAPI from '../api/tags'
import { Tag } from '../models/Tag'
import { useGenericQuery } from '../utils/queries'

const TAGS_QUERY_KEY = 'tags'

export const useTagsList = () =>
  useGenericQuery({
    queryFn: () => tagsAPI.fetchTags(),
    queryKey: [TAGS_QUERY_KEY],
    aliases: {
      data: 'tagsList',
      error: 'tagsListError',
      isLoading: 'isLoadingTagsList',
      isSuccess: 'isSuccessTagsList',
    },
  })

export const useTagDetails = (tag: Tag['name']) =>
  useGenericQuery({
    queryFn: () => tagsAPI.fetchTagDetails(tag),
    queryKey: [TAGS_QUERY_KEY, tag],
    aliases: {
      data: 'tagDetails',
      error: 'tagDetailsError',
      isLoading: 'isLoadingTagDetails',
      isSuccess: 'isSuccessTagDetails',
    },
  })
