import { FC, Fragment, PropsWithChildren, Suspense } from 'react'

import {
  Route,
  Routes,
  Navigate,
  useLocation,
  BrowserRouter,
} from 'react-router-dom'

import appRoutes, { RouteDefinition } from './appRoutes'
import { useAuthContext } from '../contexts'
import { PageLayout } from '../components'

const Private: FC<PropsWithChildren> = ({ children }) => {
  const { isLoggedIn, verifiedAuth } = useAuthContext()
  const location = useLocation()

  if (!verifiedAuth) {
    return <div /> // spinner
  }

  if (!isLoggedIn)
    return <Navigate to="/login" state={{ from: location }} replace />

  return <>{children}</>
}

const renderRoute = (def: RouteDefinition) => {
  const Component = def.Component

  const LayoutWrapper: FC<PropsWithChildren> = def.withLayout
    ? ({ children }) => <PageLayout>{children}</PageLayout>
    : Fragment

  const PrivateWrapper: FC<PropsWithChildren> = def.private
    ? ({ children }) => <Private>{children}</Private>
    : Fragment

  return (
    <Route
      key={def.path}
      path={def.path}
      element={
        <PrivateWrapper>
          <LayoutWrapper>
            <Component />
          </LayoutWrapper>
        </PrivateWrapper>
      }
    />
  )
}

const AppRouter = () => (
  <BrowserRouter>
    <Suspense fallback={<div /> /* spinner */}>
      <Routes>
        {appRoutes.map(renderRoute)}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
)

export default AppRouter
