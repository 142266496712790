import { FC } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PrimeReactProvider, addLocale } from 'primereact/api'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { SnackbarProvider } from 'notistack'
import { AuthProvider } from './contexts'
import { AppRouter } from './routing'
import esLocale from './esLocale.json'

addLocale('es', esLocale)

const queryClient = new QueryClient()

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <PrimeReactProvider value={{ locale: 'es' }}>
      <AuthProvider>
        <ConfirmDialog />
        <SnackbarProvider />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <AppRouter />
      </AuthProvider>
    </PrimeReactProvider>
  </QueryClientProvider>
)

export default App
