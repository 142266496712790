import { FC } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import { post } from '../../utils/api'
import { useAuthContext } from '../../contexts'

// @ts-expect-error
import Logo from '../../assets/no-text-logo.svg?react'

import './TrialSubscriptionDialog.css'

interface Props {
  open: boolean
}

const TrialSubscriptionDialog: FC<Props> = ({ open }) => {
  const { refreshUser } = useAuthContext()

  const getTrial = async () => {
    await post('/subscriptions/trial', {})
    await refreshUser()
  }

  return (
    <Dialog
      visible={open}
      onHide={() => {}}
      content={() => (
        <div className="subscription-dialog">
          <div className="title-wrapper">
            <div className="logo-container">
              <Logo />
            </div>
            <h1>
              ¡Te damos la bienvenida a Prospera, tu camino a la libertad
              financiera!
            </h1>
          </div>

          <div className="text-container">
            <div>
              <p>
                Gracias por unirte a nuestra comunidad de usuarios comprometidos
                con el crecimiento personal y profesional.
              </p>

              <p>
                Por ser parte de nuestros primeros 100 usuarios, disfrutarás de
                UN MES de todas las funciones PREMIUM GRATIS. Esto te permitirá
                explorar todas las herramientas y recursos que Prospera tiene
                para ofrecer, sin ningún costo inicial. Tampoco te pediremos
                ningún medio de pago hasta que termine tu MES GRATIS y decidas
                quedarte con todas las funciones PREMIUM de Prospera.
              </p>
              <p>¡Comienza tu viaje hacia el éxito con Prospera!</p>
            </div>
          </div>

          <Button
            severity="info"
            size="large"
            label="Comenzar"
            onClick={() => getTrial()}
          />
        </div>
      )}
    />
  )
}

export default TrialSubscriptionDialog
