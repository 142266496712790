import { ReactElement } from 'react'
import cx from 'classnames'
import { ClassNamesConfig } from 'react-select'
import CreatableReactSelect from 'react-select/creatable'
import './Select.css'

interface Option<T> {
  label: string | ReactElement
  value: string | number
  isFixed?: boolean
  isDisabled?: boolean
  data?: T
}

interface Props<T> {
  options: Option<T>[]
  value?: Option<T> | Option<T>[] // not right, only [] when isMulti = true
  defaultValue?: Option<T>
  onChange?: (v: Option<T> | null) => void
  onBlur?: () => void
  name?: string
  isLoading?: boolean
  isDisabled?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  label?: string
  error?: string
  isMulti?: boolean
  noOptionsMessage?: string
}

interface CreatableProps<T> extends Props<T> {
  onCreate: (v: string) => void
}

function CreatableSelect<T>({
  name,
  value,
  onChange,
  onBlur,

  options,
  isLoading = false,
  isDisabled = false,
  isSearchable = false,
  isClearable = false,
  isMulti = false,
  label,
  error,
  onCreate,
  noOptionsMessage,
}: CreatableProps<T>) {
  const classNames: ClassNamesConfig<Option<T>, false> = {
    control: () => 'control',
    menuPortal: () => 'menu',
  }

  return (
    <div className="select-wrapper">
      <div className="label-error-wrapper">
        {label && <label htmlFor={name}>{label}</label>}
        {error && <span className="error-message">{error}</span>}
      </div>
      <CreatableReactSelect
        name={name}
        className={cx('select', { 'with-error': !!error })}
        classNames={classNames}
        menuPosition="fixed"
        isLoading={isLoading}
        isDisabled={isDisabled}
        isSearchable={!/Mobi/i.test(window.navigator.userAgent) && isSearchable} // disable searchable for mobile (bug with input autofocus)
        isClearable={isClearable}
        // @ts-expect-error
        isMulti={isMulti}
        options={options}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onCreateOption={onCreate}
        blurInputOnSelect
        captureMenuScroll
        closeMenuOnSelect
        hideSelectedOptions
        noOptionsMessage={() => noOptionsMessage ?? 'Sin opciones'}
        placeholder=""
        loadingMessage={() => 'Cargando...'}
        formatCreateLabel={(inputValue) => `Crear "${inputValue}"`}
      />
    </div>
  )
}

export { CreatableSelect }
