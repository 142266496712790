import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { differenceInDays, isPast, parse } from 'date-fns'
import { Messages } from 'primereact/messages'

import Navbar from './Navbar'
import { useAuthContext } from '../../contexts'
import MPSubscriptionDialog from '../MPSubscriptionDialog'
import TrialSubscriptionDialog from '../TrialSubscriptionDialog'
import config from '../../config'

import './PageLayout.css'

const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const [trialDialogOpen, setTrialDialogOpen] = useState(false)
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false)
  const { user } = useAuthContext()

  const msgsRef = useRef(null)

  useEffect(() => {
    if (!user) return

    const nonSubscribed = !user.subscription

    const hasUnfulfilledMPSubscription =
      !!user.subscription &&
      // user.subscription.type === 'MP' &&
      !!user.subscription.status &&
      user.subscription.status !== 'authorized'

    const hasTrialSubscriptionExpired =
      !!user.subscription &&
      user.subscription.type === 'trial' &&
      isPast(parse(user.subscription.expires_at, 'yyyy-MM-dd', new Date()))

    if (nonSubscribed) {
      setTrialDialogOpen(true)
    } else if (hasUnfulfilledMPSubscription || hasTrialSubscriptionExpired) {
      setSubscriptionDialogOpen(true)
    }

    if (
      !nonSubscribed &&
      !hasUnfulfilledMPSubscription &&
      !hasTrialSubscriptionExpired
    ) {
      setTrialDialogOpen(false)
      setSubscriptionDialogOpen(false)
    }
  }, [user])

  useEffect(() => {
    if (!user) return

    const hasTrialSubscription =
      !!user.subscription && user.subscription.type === 'trial'

    if (hasTrialSubscription) {
      const daysToExpire = differenceInDays(
        parse(user.subscription!.expires_at, 'yyyy-MM-dd', new Date()),
        new Date()
      )

      if (
        daysToExpire <= 2 &&
        Number(localStorage.getItem(config.trialBannerKey)) !== daysToExpire
      ) {
        // @ts-expect-error
        msgsRef.current?.clear()

        // @ts-expect-error
        msgsRef.current?.show({
          id: 1,
          sticky: true,
          severity: 'info',
          detail: `${daysToExpire === 1 ? 'Queda' : `Quedan`} ${daysToExpire} ${daysToExpire === 1 ? 'día' : `días`} para que tu período de prueba termine.`,
          closable: true,
        })
      }
    }
  })

  if (!user) {
    return null
  }

  return (
    <div className="page-layout">
      <nav>
        <div className="nav-content">
          <Navbar />
        </div>
      </nav>
      <main>
        <div className="container">
          <Messages
            onRemove={() => {
              localStorage.setItem(
                config.trialBannerKey,
                differenceInDays(
                  parse(
                    user.subscription!.expires_at,
                    'yyyy-MM-dd',
                    new Date()
                  ),
                  new Date()
                ).toString()
              )
            }}
            ref={msgsRef}
          />
          {children}
        </div>
      </main>
      <TrialSubscriptionDialog open={trialDialogOpen} />
      <MPSubscriptionDialog open={subscriptionDialogOpen} />
    </div>
  )
}

export default PageLayout
