import { FC, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

import { Dropdown } from 'primereact/dropdown'

import { Expense } from '../models/Expense'
import { useCategoriesList, useCreateCategory } from '../queries/categories'
import { CreatableSelect } from '../components'
import { usePaymentMethodsList } from '../queries/paymentMethods'
import { useTagsList } from '../queries/tags'

interface FormFieldProps<FormValues extends FieldValues> {
  control: Control<FormValues>
  name: Path<FormValues>
  error?: string
}

export const useCreatableCategoriesDropdown = <FormValues extends FieldValues>(
  expenseType: Expense['type']
) => {
  const { categoriesList, isLoadingCategoriesList, isSuccessCategoriesList } =
    useCategoriesList()

  const { createCategory, /* createCategoryError,*/ isCreatingCategory } =
    useCreateCategory()

  const categoryOptions = isSuccessCategoriesList
    ? categoriesList
        .filter((c) => c.type === expenseType)
        .toSorted((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
        .map((c) => ({
          value: c.id,
          label: c.name,
          data: c,
        }))
    : []

  const CategoriesDropdown: FC<FormFieldProps<FormValues>> = ({
    control,
    name,
    error,
  }) => (
    <Controller
      control={control}
      name={name}
      rules={{ required: 'Requerido' }}
      render={({ field: { onChange, onBlur, value } }) => (
        <CreatableSelect
          label="Categoria"
          noOptionsMessage="Escribe para crear una nueva categoría"
          isSearchable
          options={categoryOptions}
          value={categoryOptions.find((c) => c.value === value)}
          onChange={(v) => onChange(v?.value)}
          onBlur={onBlur}
          onCreate={async (categoryName) => {
            const newCategory = await createCategory({
              name: categoryName,
              type: expenseType,
              indispensable: false,
            })
            onChange(newCategory.id)
          }}
          isLoading={isLoadingCategoriesList || isCreatingCategory}
          error={error}
        />
      )}
    />
  )

  return {
    CategoriesDropdown,
    isCreatingCategory,
  }
}

export const usePaymentMethodsDropdown = <FormValues extends FieldValues>() => {
  const {
    paymentMethodsList,
    // isLoadingPaymentMethodsList,
    isSuccessPaymentMethodsList,
  } = usePaymentMethodsList()

  const paymentMethodsOptions = isSuccessPaymentMethodsList
    ? paymentMethodsList
        .toSorted((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
        .map((pm) => ({
          value: pm.id,
          label: pm.name,
        }))
    : []

  const PaymentMethodsDropdown: FC<FormFieldProps<FormValues>> = ({
    control,
    name,
    error,
  }) => (
    <Controller
      control={control}
      rules={{ required: 'Requerido' }}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <div className="field">
          <label htmlFor={name}>Método de pago</label>
          <Dropdown
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            options={paymentMethodsOptions}
            optionLabel="label"
            invalid={!!error}
          />
        </div>
      )}
    />
  )

  return PaymentMethodsDropdown
}

export const useCurrencyDropdown = <FormValues extends FieldValues>() => {
  const CurrencyDropdown: FC<FormFieldProps<FormValues>> = ({
    control,
    name,
    error,
  }) => {
    const currencyOptions = [
      { value: 'UYU', label: 'UYU' },
      { value: 'USD', label: 'USD' },
      { value: 'ARS', label: 'ARS' },
    ]

    return (
      <Controller
        control={control}
        rules={{ required: 'Requerido' }}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="field">
            <label htmlFor={name}>Moneda</label>
            <Dropdown
              id={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              options={currencyOptions}
              optionValue="value"
              optionLabel="label"
              invalid={!!error}
            />
          </div>
        )}
      />
    )
  }

  return CurrencyDropdown
}

export const useTagsDropdown = <FormValues extends FieldValues>() => {
  const [volatileTagOptions, setVolatileTagOptions] = useState<
    { label: string; value: string }[]
  >([])

  const { tagsList, isLoadingTagsList, isSuccessTagsList } = useTagsList()

  const tagOptions = isSuccessTagsList
    ? tagsList
        .toSorted((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
        .map((t) => ({
          value: t.name,
          label: t.name,
        }))
    : []

  const TagsDropdown: FC<FormFieldProps<FormValues>> = ({
    control,
    name,
    error,
  }) => (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <CreatableSelect
          label="Tags"
          noOptionsMessage="Escribe para crear un nuevo tag"
          options={tagOptions}
          value={[
            ...tagOptions.filter((t) => value.includes(t.value)),
            ...volatileTagOptions.filter((t) => value.includes(t.value)),
          ]}
          onChange={(newVals) =>
            onChange(
              (newVals as unknown as { label: string; value: string }[])?.map(
                (v) => v.value
              )
            )
          }
          onBlur={onBlur}
          isSearchable
          isMulti
          isLoading={isLoadingTagsList}
          error={error}
          onCreate={async (tagName) => {
            setVolatileTagOptions((v) => [
              ...v,
              {
                label: tagName,
                value: tagName,
              },
            ])

            onChange([...value, tagName])
          }}
        />
      )}
    />
  )

  return TagsDropdown
}
