import { Category } from '../models/Category'
import { Expense } from '../models/Expense'
import { del, get, post, patch } from '../utils/api'

type CategoryData = Pick<Category, 'name' | 'type' | 'indispensable'>

export type CategoryWithDetails = Category & {
  earliest_date: Expense['date'] | null
  latest_date: Expense['date'] | null
  monthly_average: number | null
}

type CategoryWithDetailsAndExpenses = CategoryWithDetails & {
  expenses: Expense[]
}

const categoriesAPI = {
  fetchCategories: () => get<Category[]>('/categories'),
  fetchAllCategoryDetails: () =>
    get<CategoryWithDetails[]>(`/categories/details`),
  fetchCategoryDetails: (id: Category['id']) =>
    get<CategoryWithDetailsAndExpenses>(`/categories/details/${id}`),
  createCategory: (categoryData: CategoryData) =>
    post<Category>('/categories', categoryData),
  editCategory: ({
    id,
    name,
    type,
    indispensable,
  }: Partial<CategoryData> & { id: Category['id'] }) =>
    patch<Category>(`/categories/${id}`, { name, type, indispensable }),
  deleteCategory: (id: Category['id']) => del(`/categories/${id}`),
}

export default categoriesAPI
