import paymentMethodsAPI from '../api/paymentMethods'
import { PaymentMethod } from '../models/PaymentMethod'
import { useGenericQuery, useGenericMutation } from '../utils/queries'

const PAYMENT_METHODS_QUERY_KEY = 'payment-methods'

export const usePaymentMethodsList = () =>
  useGenericQuery({
    queryFn: () => paymentMethodsAPI.fetchPaymentMethods(),
    queryKey: [PAYMENT_METHODS_QUERY_KEY],
    aliases: {
      data: 'paymentMethodsList',
      error: 'paymentMethodsListError',
      isLoading: 'isLoadingPaymentMethodsList',
      isSuccess: 'isSuccessPaymentMethodsList',
    },
  })

export const usePaymentMethodDetails = (id: PaymentMethod['id']) =>
  useGenericQuery({
    queryFn: () => paymentMethodsAPI.fetchPaymentMethodDetails(id),
    queryKey: [PAYMENT_METHODS_QUERY_KEY, id],
    aliases: {
      data: 'paymentMethodDetails',
      error: 'paymentMethodDetailsError',
      isLoading: 'isLoadingPaymentMethodDetails',
      isSuccess: 'isSuccessPaymentMethodDetails',
    },
  })

export const useCreatePaymentMethod = () =>
  useGenericMutation({
    mutationFn: paymentMethodsAPI.createPaymentMethod,
    invalidates: [PAYMENT_METHODS_QUERY_KEY],
    aliases: {
      mutate: 'createPaymentMethod',
      error: 'createPaymentMethodError',
      isLoading: 'isCreatingPaymentMethod',
    },
  })

export const useEditPaymentMethod = () =>
  useGenericMutation({
    mutationFn: paymentMethodsAPI.editPaymentMethod,
    invalidates: [PAYMENT_METHODS_QUERY_KEY],
    aliases: {
      mutate: 'editPaymentMethod',
      error: 'editPaymentMethodError',
      isLoading: 'isEditingPaymentMethod',
    },
  })

export const useDeletePaymentMethod = () =>
  useGenericMutation({
    mutationFn: paymentMethodsAPI.deletePaymentMethod,
    invalidates: [PAYMENT_METHODS_QUERY_KEY],
    aliases: {
      mutate: 'deletePaymentMethod',
      error: 'deletePaymentMethodError',
      isLoading: 'isDeletingPaymentMethod',
    },
  })
