import { LoginResponse, VerifyResponse } from '../models/Auth'
import { User } from '../models/User'
import { post } from '../utils/api'

const authAPI = {
  login: (email: User['email'], password: string) =>
    post<LoginResponse>(
      '/auth/login',
      { email, password },
      { authenticated: false }
    ),
  loginWithGoogle: (credential: string) =>
    post<LoginResponse>(
      '/auth/google-login',
      { credential },
      { authenticated: false }
    ),
  demoLogin: (email: User['email']) =>
    post<LoginResponse>(
      '/auth/login/demo',
      { email },
      { authenticated: false }
    ),
  verifyToken: (token: string) =>
    post<VerifyResponse>('/auth/verify', { access_token: token }),
}

export default authAPI
