import { FC, lazy } from 'react'

export type AppPath =
  | '/login'
  | '/'
  | '/expenses'
  | '/categories'
  | '/payment-methods'
  | '/demo'
  | '/tags'
  | '/user-config'

export interface RouteDefinition {
  path: AppPath
  Component: FC
  private: boolean
  withLayout: boolean
}

const LoginRoute: RouteDefinition = {
  path: '/login',
  Component: lazy(() => import('../pages/Login')),
  private: false,
  withLayout: false,
}

const DashboardRoute: RouteDefinition = {
  path: '/',
  Component: lazy(() => import('../pages/Dashboard')),
  private: true,
  withLayout: true,
}

const ExpensesRoute: RouteDefinition = {
  path: '/expenses',
  Component: lazy(() => import('../pages/Expenses')),
  private: true,
  withLayout: true,
}

const CategoriesRoute: RouteDefinition = {
  path: '/categories',
  Component: lazy(() => import('../pages/Categories')),
  private: true,
  withLayout: true,
}

const PaymentMethodsRoute: RouteDefinition = {
  path: '/payment-methods',
  Component: lazy(() => import('../pages/PaymentMethods')),
  private: true,
  withLayout: true,
}

const DemoProfilesRoute: RouteDefinition = {
  path: '/demo',
  Component: lazy(() => import('../pages/DemoProfiles')),
  private: false,
  withLayout: false,
}

const TagsRoute: RouteDefinition = {
  path: '/tags',
  Component: lazy(() => import('../pages/Tags')),
  private: true,
  withLayout: true,
}

const UserConfigurationRoute: RouteDefinition = {
  path: '/user-config',
  Component: lazy(() => import('../pages/UserConfiguration')),
  private: true,
  withLayout: true,
}

const appRoutes = [
  LoginRoute,
  DashboardRoute,
  ExpensesRoute,
  CategoriesRoute,
  PaymentMethodsRoute,
  ...(import.meta.env?.PROD ? [] : [DemoProfilesRoute]),
  TagsRoute,
  UserConfigurationRoute,
]

interface NavbarElement {
  icon: string
  title: string
  to: AppPath
}

export const navbarElements: NavbarElement[] = [
  { icon: 'pi pi-home', title: 'Inicio', to: '/' },
  {
    icon: 'pi pi-money-bill',
    title: 'Movimientos',
    to: '/expenses',
  },
  {
    icon: 'pi pi-book',
    title: 'Categorías',
    to: '/categories',
  },
  {
    icon: 'pi pi-bookmark',
    title: 'Tags',
    to: '/tags',
  },
  {
    icon: 'pi pi-credit-card',
    title: 'Métodos de pago',
    to: '/payment-methods',
  },
]

export default appRoutes
