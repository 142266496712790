import { Expense } from '../models/Expense'
import { PaymentMethod } from '../models/PaymentMethod'
import { del, get, post, patch } from '../utils/api'

type PaymentMethodData = Pick<
  PaymentMethod,
  'name' | 'type' | 'brand' | 'cutoff_day' | 'due_day'
>

const paymentMethodsAPI = {
  fetchPaymentMethods: () => get<PaymentMethod[]>('/payment-methods'),
  fetchPaymentMethodDetails: (id: PaymentMethod['id']) =>
    get<PaymentMethod & { expenses: Expense[] }>(`/payment-methods/${id}`),
  createPaymentMethod: (paymentMethodData: PaymentMethodData) =>
    post<PaymentMethod>('/payment-methods', paymentMethodData),
  editPaymentMethod: ({
    id,
    name,
    type,
    brand,
    cutoff_day,
    due_day,
  }: { id: PaymentMethod['id'] } & Partial<PaymentMethodData>) =>
    patch<PaymentMethod>(`/payment-methods/${id}`, {
      name,
      type,
      brand,
      cutoff_day,
      due_day,
    }),
  deletePaymentMethod: (id: PaymentMethod['id']) =>
    del(`/payment-methods/${id}`),
}

export default paymentMethodsAPI
