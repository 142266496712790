import { FC, useEffect } from 'react'
import { format, parse } from 'date-fns'
import { enqueueSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { TabPanel, TabView } from 'primereact/tabview'
import { Divider } from 'primereact/divider'

import { Expense } from '../../models/Expense'

import {
  useCreateExpense,
  useCreateInstallmentExpense,
  useEditExpense,
} from '../../queries/expenses'
import { usePaymentMethodsList } from '../../queries/paymentMethods'

import {
  useCreatableCategoriesDropdown,
  useCurrencyDropdown,
  usePaymentMethodsDropdown,
  useTagsDropdown,
} from '../../utils/forms'

import './CreateEditExpenseModal.css'

export interface FormValues {
  date: Date
  name: Expense['name']
  type: Expense['type']
  currency: Expense['currency']
  total: Expense['total'] | null
  notes: Expense['notes']
  category_id: Expense['category']['id']
  payment_method_id: number | null // TODO: improve
  tags: Expense['tags']

  // installment expense fields
  installments?: number | null
}

interface Props {
  visible: boolean
  onClose: () => void
  expenseToEdit?: Expense
}

const CreateEditExpenseModal: FC<Props> = ({
  visible,
  onClose,
  expenseToEdit,
}) => {
  const { createExpense, /* createExpenseError,*/ isCreatingExpense } =
    useCreateExpense()

  const { editExpense, /* editExpenseError,*/ isEditingExpense } =
    useEditExpense()

  const { createInstallmentExpense, isCreatingInstallmentExpense } =
    useCreateInstallmentExpense()

  const { paymentMethodsList } = usePaymentMethodsList()

  // const submitError =
  //   createExpenseError || editExpenseError || createCategoryError

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    unregister,
    formState: { isSubmitting, errors, isValid },
  } = useForm<FormValues>({
    defaultValues: expenseToEdit
      ? {
          date: parse(expenseToEdit.date, 'yyyy-MM-dd', new Date()),
          name: expenseToEdit.name,
          currency: expenseToEdit.currency,
          total: expenseToEdit.total,
          category_id: expenseToEdit.category.id,
          type: expenseToEdit.type,
          notes: expenseToEdit.notes,
          payment_method_id: expenseToEdit.paymentMethod?.id ?? null,
          tags: expenseToEdit.tags,
        }
      : {
          date: new Date(),
          currency: 'UYU',
          type: 'expense',
          tags: [],
          name: '',
          total: null,
          installments: null,
        },
    mode: 'all',
  })

  const selectedType = watch('type')
  const selectedCurrency = watch('currency')
  const selectedPaymentMethodId = watch('payment_method_id')
  const selectedInstallments = watch('installments')

  const selectedPaymentMethod = paymentMethodsList?.find(
    (paymentMethod) => paymentMethod.id === selectedPaymentMethodId
  )

  const onSubmit = async (values: FormValues) => {
    const { date, total, installments, ...rest } = values

    try {
      if (expenseToEdit) {
        await editExpense({
          id: expenseToEdit.id,
          date: format(date, 'yyyy-MM-dd') as Expense['date'],
          total: total!,
          ...rest,
        })
        enqueueSnackbar(`${values.name} editado con éxito`, {
          variant: 'success',
        })
      } else {
        if (selectedPaymentMethod?.type === 'credit_card') {
          await createInstallmentExpense({
            date: format(date, 'yyyy-MM-dd') as Expense['date'],
            installments: installments!,
            total: total!,
            ...rest,
          })
        } else {
          await createExpense({
            date: format(date, 'yyyy-MM-dd') as Expense['date'],
            total: total!,
            ...rest,
          })
        }

        enqueueSnackbar(`${values.name} creado con éxito`, {
          variant: 'success',
        })
      }

      onClose()
    } catch (e) {
      expenseToEdit
        ? enqueueSnackbar(`Error editando ${expenseToEdit.name}`, {
            variant: 'error',
          })
        : enqueueSnackbar(`Error creando ${values.name}`, {
            variant: 'error',
          })
    }
  }

  useEffect(() => {
    if (selectedType === 'income') {
      unregister('payment_method_id', {
        keepValue: true,
        keepDirty: true,
        keepError: false,
      })
      setValue('payment_method_id', undefined!)
      setValue('category_id', undefined!)
    }
  }, [selectedType])

  useEffect(() => {
    if (!selectedPaymentMethod) return
    if (selectedPaymentMethod.type !== 'credit_card') {
      unregister('installments')
    }
  }, [selectedPaymentMethod])

  const PaymentMethodsDropdown = usePaymentMethodsDropdown<FormValues>()
  const CurrencyDropdown = useCurrencyDropdown<FormValues>()
  const TagsDropdown = useTagsDropdown<FormValues>()
  const { CategoriesDropdown, isCreatingCategory } =
    useCreatableCategoriesDropdown<FormValues>(selectedType)

  const isCreatingOrEditing =
    isCreatingExpense ||
    isCreatingInstallmentExpense ||
    isEditingExpense ||
    isCreatingCategory

  return (
    <Dialog
      className="create-edit-expense-modal"
      header={
        expenseToEdit
          ? `Editar ${expenseToEdit.name}`
          : 'Crear nuevo movimiento'
      }
      visible={visible}
      onHide={onClose}
      footer={() => (
        <>
          <Button
            label="Cancelar"
            icon="pi pi-times"
            severity="secondary"
            onClick={onClose}
            className="p-button-text"
          />
          <Button
            label="Guardar"
            icon="pi pi-save"
            onClick={handleSubmit(onSubmit)}
            autoFocus
            disabled={isSubmitting || isCreatingOrEditing || !isValid}
          />
        </>
      )}
    >
      <form>
        <TabView
          activeIndex={selectedType === 'expense' ? 0 : 1}
          onTabChange={(e) => {
            setValue('type', e.index === 0 ? 'expense' : 'income')
          }}
        >
          <TabPanel header="Gasto" />
          <TabPanel header="Ingreso" />
        </TabView>
        <div className="columns">
          <Controller
            control={control}
            rules={{ required: 'Requerido' }}
            name="name"
            render={({ field: { onChange, value, onBlur } }) => (
              <div className="field">
                <label htmlFor="name">Nombre</label>
                <InputText
                  id="name"
                  value={value}
                  onInput={onChange}
                  onBlur={onBlur}
                  invalid={!!errors.name?.message}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            rules={{ required: 'Requerido' }}
            name="total"
            render={({ field: { value, onChange, onBlur } }) => (
              <div className="field">
                <label htmlFor="total">Total</label>
                <InputNumber
                  id="total"
                  value={value}
                  onValueChange={onChange}
                  onBlur={onBlur}
                  mode="currency"
                  currency={selectedCurrency}
                  locale="en-US"
                />
              </div>
            )}
          />
        </div>
        <div className="columns">
          <CategoriesDropdown
            name="category_id"
            control={control}
            error={errors.category_id?.message}
          />

          {selectedType === 'expense' && (
            <PaymentMethodsDropdown
              name="payment_method_id"
              control={control}
              error={errors.payment_method_id?.message}
            />
          )}
        </div>

        {!expenseToEdit && selectedPaymentMethod?.type === 'credit_card' && (
          <div className="columns">
            <Controller
              control={control}
              rules={{ required: 'Requerido' }}
              name="installments"
              render={({ field: { value, onChange, onBlur } }) => (
                <div className="field">
                  <label htmlFor="installments">Cuotas</label>
                  <InputNumber
                    id="installments"
                    value={value}
                    onValueChange={onChange}
                    onBlur={onBlur}
                  />
                </div>
              )}
            />
            {selectedInstallments === 1 && (
              <div className="field">
                <label style={{ height: 20 }} />
                <small>
                  * El gasto quedará reflejado el día del vencimiento de la
                  tarjeta con la que lo pagaste.
                </small>
              </div>
            )}
          </div>
        )}

        <Divider className="divider" />

        <div className="columns">
          <Controller
            control={control}
            rules={{ required: 'Requerido' }}
            name="date"
            render={({ field: { onChange, onBlur, value } }) => (
              <div className="field">
                <label htmlFor="date">Fecha</label>
                <Calendar
                  id="date"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  dateFormat="dd/mm/yy"
                />
              </div>
            )}
          />
          <CurrencyDropdown
            name="currency"
            control={control}
            error={errors.currency?.message}
          />
        </div>

        <TagsDropdown
          name="tags"
          control={control}
          error={errors.tags?.message}
        />

        <Controller
          control={control}
          name="notes"
          render={({ field: { value, onChange, onBlur } }) => (
            <div className="field">
              <label htmlFor="notes">Notas</label>
              <InputTextarea
                id="notes"
                value={value ?? undefined}
                onChange={onChange}
                onBlur={onBlur}
                rows={3}
              />
            </div>
          )}
        />
      </form>
    </Dialog>
  )
}

export default CreateEditExpenseModal
