import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import config from '../../config'
import authAPI from '../../api/auth'
import { User } from '../../models/User'

interface AuthContextType {
  user?: User
  isLoggedIn: boolean
  verifiedAuth: boolean
  signin: (email: User['email'], password: string) => Promise<void>
  signinWithGoogle: (credential: string) => Promise<void>
  demoSignin: (email: User['email']) => Promise<void>
  signout: () => void
  refreshUser: () => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = createContext<AuthContextType>(null!)

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [verifiedAuth, setVerifiedAuth] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState<User>()

  const verifyAndRefreshToken = async () => {
    const token = localStorage.getItem(config.jwtStorageKey)

    if (!token) {
      localStorage.removeItem(config.jwtStorageKey)
      localStorage.removeItem(config.userStorageKey)

      setUser(undefined)
      setIsLoggedIn(false)

      if (!['/login', '/demo'].includes(window.location.pathname)) {
        window.location.href = '/login'
      }

      return
    }

    try {
      const response = await authAPI.verifyToken(token)

      const refreshedToken = response.access_token

      localStorage.setItem(config.jwtStorageKey, refreshedToken)
      localStorage.setItem(config.userStorageKey, JSON.stringify(response.user))
      localStorage.setItem(config.countryKey, response.country)

      setUser(response.user)
      setIsLoggedIn(true)
    } catch (e) {}
  }

  useEffect(() => {
    const verifyAuth = async () => {
      await verifyAndRefreshToken()
      setVerifiedAuth(true)
    }

    verifyAuth()

    const INTERVAL_TIME = 1000 * 60 * 10 // 10 min
    const interval = setInterval(() => {
      verifyAndRefreshToken()
    }, INTERVAL_TIME)

    return clearInterval(interval)
  }, [])

  useEffect(() => {
    if (user) {
      // @ts-ignore
      window.heap.identify(user.email)
    }
  }, [user])

  const signin = async (email: string, password: string) => {
    const loginResponse = await authAPI.login(email, password)

    const accessToken = loginResponse.access_token

    localStorage.setItem(config.jwtStorageKey, accessToken)
    localStorage.setItem(
      config.userStorageKey,
      JSON.stringify(loginResponse.user)
    )

    setUser(loginResponse.user)
    setIsLoggedIn(true)
  }

  const signinWithGoogle = async (credential: string) => {
    const loginResponse = await authAPI.loginWithGoogle(credential)

    const accessToken = loginResponse.access_token

    localStorage.setItem(config.jwtStorageKey, accessToken)
    localStorage.setItem(
      config.userStorageKey,
      JSON.stringify(loginResponse.user)
    )

    setUser(loginResponse.user)
    setIsLoggedIn(true)
  }

  const demoSignin = async (email: string) => {
    const loginResponse = await authAPI.demoLogin(email)

    const accessToken = loginResponse.access_token

    localStorage.setItem(config.jwtStorageKey, accessToken)
    localStorage.setItem(
      config.userStorageKey,
      JSON.stringify(loginResponse.user)
    )
    localStorage.setItem(config.countryKey, loginResponse.country)

    setUser(loginResponse.user)
    setIsLoggedIn(true)
  }

  const signout = () => {
    localStorage.removeItem(config.jwtStorageKey)
    localStorage.removeItem(config.userStorageKey)

    setUser(undefined)
    setIsLoggedIn(false)

    // @ts-ignore
    google.accounts.id.disableAutoSelect()
    window.location.href = '/login'
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        verifiedAuth,
        signin,
        signinWithGoogle,
        demoSignin,
        signout,
        refreshUser: verifyAndRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
