import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { RenameMany } from './types'

type QueryKey = any

interface GenericQueryParams<T, S, L, E, D> {
  queryFn: (...args: any[]) => Promise<T>
  queryKey?: QueryKey
  aliases?: { isSuccess?: S; isLoading?: L; error?: E; data?: D }
}

type QueryReturnType<
  T,
  S extends string | undefined,
  L extends string | undefined,
  E extends string | undefined,
  D extends string | undefined,
> =
  | RenameMany<
      {
        isSuccess: false
        error: unknown
        isLoading: boolean
        data: undefined
      },
      [['isSuccess', S], ['isLoading', L], ['error', E], ['data', D]]
    >
  | RenameMany<
      {
        isSuccess: true
        data: T
        isLoading: false
        error: undefined
      },
      [['isSuccess', S], ['isLoading', L], ['error', E], ['data', D]]
    >

export function useGenericQuery<
  T,
  S extends string | undefined = undefined,
  L extends string | undefined = undefined,
  E extends string | undefined = undefined,
  D extends string | undefined = undefined,
>({
  queryFn,
  queryKey,
  aliases,
}: GenericQueryParams<T, S, L, E, D>): QueryReturnType<T, S, L, E, D> {
  const { data, error, isLoading, isSuccess } = useQuery({
    queryKey,
    queryFn,
  })

  if (!isSuccess)
    // @ts-ignore
    return {
      [aliases?.isSuccess ?? 'isSuccess']: isSuccess,
      [aliases?.error ?? 'error']: error,
      [aliases?.isLoading ?? 'isLoading']: isLoading,
      [aliases?.data ?? 'data']: undefined,
    }

  // @ts-ignore
  return {
    [aliases?.isSuccess ?? 'isSuccess']: isSuccess,
    [aliases?.data ?? 'data']: data,
    [aliases?.isLoading ?? 'isLoading']: false,
    [aliases?.error ?? 'error']: undefined,
  }
}

interface GenericMutationParams<MArg, MRet, M, L, E> {
  mutationFn: (args: MArg) => Promise<MRet>
  invalidates?: QueryKey[] | ((res: MRet) => QueryKey[])
  aliases?: { mutate?: M; isLoading?: L; error?: E }
}

type MutationReturnType<
  MArg,
  MRet,
  M extends string | undefined,
  L extends string | undefined,
  E extends string | undefined,
> = RenameMany<
  { mutate: (arg: MArg) => Promise<MRet>; error: unknown; isLoading: boolean },
  [['mutate', M], ['error', E], ['isLoading', L]]
>

export function useGenericMutation<
  MArg,
  MRet,
  M extends string | undefined = undefined,
  L extends string | undefined = undefined,
  E extends string | undefined = undefined,
>({
  mutationFn,
  invalidates = [],
  aliases,
}: GenericMutationParams<MArg, MRet, M, L, E>): MutationReturnType<
  MArg,
  MRet,
  M,
  L,
  E
> {
  const queryClient = useQueryClient()

  const { mutateAsync, error, isLoading } = useMutation({
    mutationFn,
    onSuccess: (res) => {
      ;(typeof invalidates === 'function'
        ? invalidates(res)
        : invalidates
      ).forEach(async (queryKey) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKey],
          refetchType: 'all',
        })
      })
    },
  })

  // @ts-ignore
  return {
    [aliases?.mutate ?? 'mutate']: mutateAsync,
    [aliases?.error ?? 'error']: error,
    [aliases?.isLoading ?? 'isLoading']: isLoading,
  }
}
