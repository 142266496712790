import { FC } from 'react'

import { Dialog } from 'primereact/dialog'

// @ts-expect-error
import Logo from '../../assets/no-text-logo.svg?react'

import './MPSubscriptionDialog.css'

interface Props {
  open: boolean
}

const MPSubscriptionDialog: FC<Props> = ({ open }) => {
  return (
    <Dialog
      visible={open}
      onHide={() => {}}
      content={() => (
        <div className="subscription-dialog">
          <div>
            <div className="logo-container">
              <Logo />
            </div>
            <h1>Tu período de prueba ha terminado</h1>
          </div>

          <div className="text-container">
            <div>
              <p>
                Esperamos que hayas disfrutado tu mes gratuito en Prospera, y le
                hayas sacado el máximo provecho. ¡Nos alegra que formes parte de
                nuestra comunidad!
              </p>

              <p>
                Te invitamos a que nos cuentes tu experiencia usando Prospera.
                Podes hacerlo <a href="#">aquí</a> o a través de nuestras redes
                sociales:{' '}
                <a href="https://www.instagram.com/getprospera?igsh=azhoNHFzbnlicTht">
                  Instagram
                </a>{' '}
                &{' '}
                <a href="https://www.linkedin.com/company/prosperafinanzas/">
                  LinkedIn
                </a>
                . Por otras consultas, puedes escribirnos a{' '}
                <a href="mailto:contacto@getprospera.io">
                  contacto@getprospera.io
                </a>
                .
              </p>

              <p>
                Estamos trabajando en nuestro modelo de suscripción para que
                puedas continuar tu viaje hacia el éxito con Próspera.
                Escuchamos tus sugerencias y nos dedicamos a mejorar tu
                experiencia, por lo que pronto tendrás novedades de nosotros 😉
              </p>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default MPSubscriptionDialog
