import { useRef, useState } from 'react'

import { NavLink, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'

import { Avatar } from 'primereact/avatar'
import { Button } from 'primereact/button'
import { Menubar } from 'primereact/menubar'
import { Menu } from 'primereact/menu'

// @ts-expect-error
import Logo from '../../../assets/no-text-logo.svg?react'
import { navbarElements } from '../../../routing/appRoutes'
import CreateEditExpenseModal from '../../CreateEditExpenseModal'
import { useAuthContext } from '../../../contexts'

import './Navbar.css'

const Navbar = () => {
  const [createExpenseModalVisible, setCreateExpenseModalVisible] =
    useState(false)

  const { user, signout } = useAuthContext()
  const { width } = useWindowSize()
  const navigate = useNavigate()

  const menuRef = useRef(null)

  return (
    <div className="navbar">
      <Menubar
        start={
          <div className="logo-wrapper">
            <NavLink to="/">
              <Logo />
            </NavLink>
          </div>
        }
        model={navbarElements.map(({ icon, title, to }) => ({
          label: title,
          icon,
          url: to,
        }))}
        end={
          <div className="end-wrapper">
            {width >= 990 && window.location.pathname !== '/expenses' && (
              <Button
                label={width >= 1180 ? 'Nuevo movimiento' : undefined}
                icon="pi pi-plus"
                onClick={() => setCreateExpenseModalVisible(true)}
              />
            )}
            <Avatar
              shape="circle"
              size="large"
              // @ts-expect-error too lazy to type ref or look how to type it :D sorry
              onClick={menuRef.current?.toggle}
              image={user?.photoURL}
            />
            <Menu
              model={[
                {
                  label: `${user?.firstName} ${user?.lastName}`,
                  items: [
                    {
                      label: 'Preferencias',
                      icon: 'pi pi-cog',
                      command: () => navigate('/user-config'),
                    },
                    {
                      label: 'Salir',
                      icon: 'pi pi-sign-out',
                      command: () => signout(),
                    },
                  ],
                },
              ]}
              popup
              ref={menuRef}
              popupAlignment="right"
            />
          </div>
        }
      />
      {createExpenseModalVisible && (
        <CreateEditExpenseModal
          visible
          onClose={() => setCreateExpenseModalVisible(false)}
        />
      )}
    </div>
  )
}

export default Navbar
