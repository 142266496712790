import { format } from 'date-fns'
import expensesAPI from '../api/expenses'
import { Expense } from '../models/Expense'
import { useGenericQuery, useGenericMutation } from '../utils/queries'

const EXPENSES_QUERY_KEY = 'expenses'
const LAST_N_EXPENSES_QUERY_KEY = 'last-n-expenses'
const EXPENSES_BY_TAG_QUERY_KEY = 'expenses-by-tag'
const RECURRING_EXPENSES_QUERY_KEY = 'recurring-expenses'
const INCOMPLETE_EXPENSES_QUERY_KEY = 'incomplete-expenses'
const INSTALLMENT_EXPENSES_QUERY_KEY = 'installment-expenses'

export const useExpensesList = (from: Date, to: Date) =>
  useGenericQuery({
    queryFn: () =>
      expensesAPI.fetchExpensesForRange(
        format(from, 'yyyy-MM-dd') as Expense['date'],
        format(to, 'yyyy-MM-dd') as Expense['date']
      ),
    queryKey: [EXPENSES_QUERY_KEY, from, to],
    aliases: {
      data: 'expensesList',
      error: 'expensesListError',
      isLoading: 'isLoadingExpensesList',
      isSuccess: 'isSuccessExpensesList',
    },
  })

export const useLastNExpenses = (n: number) =>
  useGenericQuery({
    queryFn: () => expensesAPI.fetchLastNExpenses(n),
    queryKey: [LAST_N_EXPENSES_QUERY_KEY, n],
    aliases: {
      data: 'lastNExpenses',
      error: 'lastNExpensesListError',
      isLoading: 'isLoadingLastNExpenses',
      isSuccess: 'isSuccessLastNExpenses',
    },
  })

export const useExpensesByTag = (tag: Expense['tags'][number]) =>
  useGenericQuery({
    queryFn: () => expensesAPI.fetchExpensesByTag(tag),
    queryKey: [EXPENSES_BY_TAG_QUERY_KEY, tag],
    aliases: {
      data: 'expensesByTag',
      error: 'expensesByTagError',
      isLoading: 'isLoadingExpensesByTag',
      isSuccess: 'isSuccessExpensesByTag',
    },
  })

export const useCreateExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.createExpense,
    invalidates: [EXPENSES_QUERY_KEY, LAST_N_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'createExpense',
      error: 'createExpenseError',
      isLoading: 'isCreatingExpense',
    },
  })

export const useEditExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.editExpense,
    invalidates: [EXPENSES_QUERY_KEY, LAST_N_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'editExpense',
      error: 'editExpenseError',
      isLoading: 'isEditingExpense',
    },
  })

export const useDeleteExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.deleteExpense,
    invalidates: [EXPENSES_QUERY_KEY, LAST_N_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'deleteExpense',
      error: 'deleteExpenseError',
      isLoading: 'isDeletingExpense',
    },
  })

export const useRecurringExpensesList = () =>
  useGenericQuery({
    queryFn: () => expensesAPI.fetchRecurringExpenses(),
    queryKey: [RECURRING_EXPENSES_QUERY_KEY],
    aliases: {
      data: 'recurringExpensesList',
      error: 'recurringExpensesListError',
      isLoading: 'isLoadingRecurringExpensesList',
      isSuccess: 'IsSuccessRecurringExpensesList',
    },
  })

export const useCreateRecurringExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.createRecurringExpense,
    invalidates: [RECURRING_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'createRecurringExpense',
      error: 'createRecurringExpenseError',
      isLoading: 'isCreatingRecurringExpense',
    },
  })

export const useEditRecurringExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.editRecurringExpense,
    invalidates: [RECURRING_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'editRecurringExpense',
      error: 'editRecurringExpenseError',
      isLoading: 'isEditingRecurringExpense',
    },
  })

export const useDeleteRecurringExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.deleteRecurringExpense,
    invalidates: [RECURRING_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'deleteRecurringExpense',
      error: 'deleteRecurringExpenseError',
      isLoading: 'isDeletingRecurringExpense',
    },
  })

export const useIncompleteExpensesList = () =>
  useGenericQuery({
    queryFn: () => expensesAPI.fetchIncompleteExpenses(),
    queryKey: [INCOMPLETE_EXPENSES_QUERY_KEY],
    aliases: {
      data: 'incompleteExpensesList',
      error: 'incompleteExpensesListError',
      isLoading: 'isLoadingIncompleteExpensesList',
      isSuccess: 'isSuccessIncompleteExpensesList',
    },
  })

export const useEditIncompleteExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.editIncompleteExpense,
    invalidates: [INCOMPLETE_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'editIncompleteExpense',
      error: 'editIncompleteExpenseError',
      isLoading: 'isEditingIncompleteExpense',
    },
  })

export const useCompleteIncompleteExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.completeExpense,
    invalidates: [EXPENSES_QUERY_KEY, INCOMPLETE_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'completeIncompleteExpense',
      error: 'completeIncompleteExpenseError',
      isLoading: 'isCompletingIncompleteExpense',
    },
  })

export const useDeleteIncompleteExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.deleteIncompleteExpense,
    invalidates: [INCOMPLETE_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'deleteIncompleteExpense',
      error: 'deleteIncompleteExpenseError',
      isLoading: 'isDeletingIncompleteExpense',
    },
  })

export const useInstallmentExpensesList = () =>
  useGenericQuery({
    queryFn: () => expensesAPI.fetchInstallmentExpenses(),
    queryKey: [INSTALLMENT_EXPENSES_QUERY_KEY],
    aliases: {
      data: 'installmentExpensesList',
      error: 'installmentExpensesListError',
      isLoading: 'isLoadingInstallmentExpensesList',
      isSuccess: 'isSuccessInstallmentExpensesList',
    },
  })

export const useCreateInstallmentExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.createInstallmentExpense,
    invalidates: [
      INSTALLMENT_EXPENSES_QUERY_KEY,
      EXPENSES_QUERY_KEY,
      LAST_N_EXPENSES_QUERY_KEY,
    ],
    aliases: {
      mutate: 'createInstallmentExpense',
      error: 'createInstallmentExpenseError',
      isLoading: 'isCreatingInstallmentExpense',
    },
  })

export const useEditInstallmentExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.editInstallmentExpense,
    invalidates: [INSTALLMENT_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'editInstallmentExpense',
      error: 'editInstallmentExpenseError',
      isLoading: 'isEditingInstallmentExpense',
    },
  })

export const useDeleteInstallmentExpense = () =>
  useGenericMutation({
    mutationFn: expensesAPI.deleteInstallmentExpense,
    invalidates: [INSTALLMENT_EXPENSES_QUERY_KEY],
    aliases: {
      mutate: 'deleteInstallmentExpense',
      error: 'deleteInstallmentExpenseError',
      isLoading: 'isDeletingInstallmentExpense',
    },
  })
