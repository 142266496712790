import { Expense } from '../models/Expense'
import { Tag } from '../models/Tag'
import { get } from '../utils/api'

type TagDetails = Tag & { expenses: Expense[] }

const tagsAPI = {
  fetchTags: () => get<Tag[]>('/tags'),
  fetchTagDetails: (tag: Tag['name']) => get<TagDetails | null>(`/tags/${tag}`),
}

export default tagsAPI
