import categoriesAPI from '../api/categories'
import { Category } from '../models/Category'
import { useGenericQuery, useGenericMutation } from '../utils/queries'

const CATEGORIES_QUERY_KEY = 'categories'
const CATEGORIES_DETAILS_QUERY_KEY = 'categories-details'

export const useCategoriesList = () =>
  useGenericQuery({
    queryFn: () => categoriesAPI.fetchCategories(),
    queryKey: [CATEGORIES_QUERY_KEY],
    aliases: {
      data: 'categoriesList',
      error: 'categoriesListError',
      isLoading: 'isLoadingCategoriesList',
      isSuccess: 'isSuccessCategoriesList',
    },
  })

export const useCategoriesDetailsList = () =>
  useGenericQuery({
    queryFn: () => categoriesAPI.fetchAllCategoryDetails(),
    queryKey: [CATEGORIES_DETAILS_QUERY_KEY],
    aliases: {
      data: 'categoriesDetailsList',
      error: 'categoriesDetailsListError',
      isLoading: 'isLoadingCategoriesDetailsList',
      isSuccess: 'isSuccessCategoriesDetailsList',
    },
  })

export const useCategoryDetails = (id: Category['id']) =>
  useGenericQuery({
    queryFn: () => categoriesAPI.fetchCategoryDetails(id),
    queryKey: [CATEGORIES_DETAILS_QUERY_KEY, id],
    aliases: {
      data: 'categoryDetails',
      error: 'categoryDetailsError',
      isLoading: 'isLoadingCategoryDetails',
      isSuccess: 'isSuccessCategoryDetails',
    },
  })

export const useCreateCategory = () =>
  useGenericMutation({
    mutationFn: categoriesAPI.createCategory,
    invalidates: [CATEGORIES_QUERY_KEY],
    aliases: {
      mutate: 'createCategory',
      error: 'createCategoryError',
      isLoading: 'isCreatingCategory',
    },
  })

export const useEditCategory = () =>
  useGenericMutation({
    mutationFn: categoriesAPI.editCategory,
    invalidates: [CATEGORIES_QUERY_KEY],
    aliases: {
      mutate: 'editCategory',
      error: 'editCategoryError',
      isLoading: 'isEditingCategory',
    },
  })

export const useDeleteCategory = () =>
  useGenericMutation({
    mutationFn: categoriesAPI.deleteCategory,
    invalidates: [CATEGORIES_QUERY_KEY],
    aliases: {
      mutate: 'deleteCategory',
      error: 'deleteCategoryError',
      isLoading: 'isDeletingCategory',
    },
  })
